// same file is using for lotRulesWithoutCheckIn file

.LotRuleSection {
  background-color: #FFFFFF;
  padding-top: 124px;
  min-height: 100vh;
  @media (max-width:1366px) {
      padding-top: 80px;
      padding-bottom: 40px;
    }
  .LotRuleHeader {
    background-color: #FCFF00;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 60px 0 60px;
    @media (max-width:992px) {
        padding: 4px 20px 0 20px;
        text-align: left;
      }
      @media (max-width:767px) {
        height: 70px;
      }
    div {
      min-height: 60px;
      @media (max-width:767px) {
        width: 100%;
        min-height: 50px;
      }
    }
    h4 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: normal;
      @media (max-width:767px) {
        margin: 10px 0 0 0;
        text-align: center;
      }
    }
    p {
      padding: 0;
      margin: 4px 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width:767px) {
        text-align: center;
      }
    }
    .check-lot-rules {
      font-size: 14px; 
      font-weight: 500;
      cursor: pointer!important;
      color: blue;
      z-index: 99;
      transition: background-color 0.3s ease;
    }
    .HeaderFlex {
      display: flex;
      align-items: center;
      @media (max-width:767px) {
        justify-content: center;
        margin-top: 20px;
      }
      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
        margin: 0 3px 0 0;
      }
      .LocationTag {        
        background: #000000;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin: 0 0 0 10px;
        padding: 14px 20px 14px 16px;
        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }
      }
      
    }
  }
  .UpdateTagLotRuleHeader {
    background-color: #FCFF00;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 60px 0 60px;
    @media (max-width:992px) {
        padding: 4px 20px 0 20px;
        text-align: left;
      }
      @media (max-width:767px) {
        height: 90px;
      }
    div {
      min-height: 60px;
      @media (max-width:767px) {
        width: 100%;
        min-height: 50px;
      }
    }
    h4 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: normal;
      @media (max-width:767px) {
        margin: 10px 0 0 0;
        text-align: center;
      }
    }
    p {
      padding: 0;
      margin: 4px 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width:767px) {
        text-align: center;
      }
    }
    .check-lot-rules {
      font-size: 14px; 
      font-weight: 500;
      cursor: pointer!important;
      color: blue;
      z-index: 99;
      transition: background-color 0.3s ease;
    }
    .HeaderFlex {
      display: flex;
      align-items: center;
      @media (max-width:767px) {
        justify-content: center;
        margin-top: 20px;
      }
      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
        margin: 0 3px 0 0;
      }
      .LocationTag {        
        background: #000000;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin: 0 0 0 10px;
        padding: 14px 20px 14px 16px;
        img {
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }
      }
      
    }
  }
  .LotRuleList {
    margin: 60px auto;
    width: 780px;
    max-width: 96%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 767px) {
        margin: 80px auto 30px;
      }
    li {
      width: calc(33.333% - 6px);
      @media (max-width: 767px) {
        width: calc(50% - 4px);
        margin-bottom: 8px;
      }
      .ImgBox {
        padding-top: 84%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 10px;
        }
      }
      &:last-child {
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 0;
        }
        .ImgBox {
          @media (max-width: 767px) {
            padding-top: 43%;
          }
        }
      }
    }
  }
  .TextDetails {
    max-width: 438px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @media (max-width:767px) {
      padding-bottom: 15px;
    }
    h4 {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: #1E1E1E;
      margin: 0 0 20px 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1E1E1E;
    }
    .Lists {
      list-style: disc;
      width: 280px;
      margin: 0 auto;
      li {
        text-align: left;
        font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #1E1E1E;
      }
    }
    .FlexBtn {
      margin: 30px 0;
      display: flex;
      justify-content: center;
      .DarkBtn {
        width: 170px;
        height: 55px;      
        background: #000000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        color: #FFFFFF;
        margin: 0 10px;
      }
      .WhiteBtn {
        width: 170px;
        height: 55px;
        background: #fff;
        color: 000000;
        border: 1px solid #000000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }
    }
    .errors {
      font-size: 16px;
      line-height: 22px;
      color: #BE1818;
    }
  }
}
        hr {
          opacity: 0.20;
        }
        .IcScan {
          width: 40px;
          cursor: pointer;
        }
        .LocationSm {
          width: 12px;
          margin-right: 4px;
        }




