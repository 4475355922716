@import "./variable.scss";
@import "./fonts.scss";


body {
  margin: 0;
  padding: 0;
  font-family: var(--font-Lato);
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: var(--xlbold);
  font-size: 50px;
  line-height: 66px;
  color: #010101;
}

h2 {
  font-size: 34px;
  line-height: 40px;
  color: #010101;
  font-weight: var(--semibold);

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 120%;
  }
}

h3 {
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  font-weight: 900;
}

h4 {
  font-size: 28px;
  line-height: 29px;
  color: #000000;
  font-weight: 900;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.BoldTitle {
  font-family: "Lato-ExtraBold";

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

p {
  font-weight: var(--normal);
  font-size: 14px;
  color: #010101;
}

input:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}



// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: var(--max-width);
  }
}

.custom__container {
  width: calc(100% - 30px);
  max-width: var(--max-width);
  margin: 0 auto;
}

a {
  color: #030dcf;
}

.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #D9DCE1;
      background: var(--bg-white);
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .ck-editor__editable {
    border: 1px solid #D9DCE1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Bootsrap overwrite

hr {
  border-color: var(--light-gray);
  opacity: 1;
}

.form-group {
  margin-bottom: 22px;

  &.inline-form-row {
    display: flex;
    justify-content: space-between;
  }
}

.ReactPhoneNumberInput {
  &.PhoneInput {
    border: 1px solid #000;
  }

  .PhoneInputCountry {
    background: #fcbe99;
    width: 85px;
    justify-content: center;
    margin-right: 0;
    border-right: 1px solid #000;

    .PhoneInputCountrySelectArrow {}
  }

  .PhoneInputInput {
    min-height: 60px;
    border: 0;
    padding: 12px 15px;

    &:focus {
      outline: 0;
    }
  }
}

.form-control {
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  color: var(--text-black);
  font-size: var(--fontbase);
  min-height: calc(1.5em + 0.75rem + 0.5rem);

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: var(--border-gray);
  }

  &.form-control-flat {
    border-radius: 0;
  }
}


.PhoneInputCountrySelectArrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  vertical-align: middle;
  transform: rotate(45deg);
}

.PhoneInputCountryIcon {
  margin-right: 4px;
}

.international-phone {
  position: relative;
  padding: 0;
  display: flex;
  overflow: hidden;
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
}

.PhoneInput--focus {
  input {
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }
}

.international-phone .PhoneInputCountry {
  position: relative;
  padding: 5px;
  top: 0;
  left: 0;
  background: #4d1b6c;
  width: 80px;
  display: flex;
  // height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem 0 0 0.3125rem;
}

.international-phone select {
  border: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.international-phone .PhoneInputCountryIconImg {
  width: 20px;
}

.international-phone .PhoneInputInput {
  width: 100%;
  padding: 5px;
  border: none;
  height: 44px;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}

textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}

select,
select.custom-select {
  appearance: none;
  // background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
  cursor: pointer;
}

.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}

.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
}

.invalid-feedback {
  display: block;
  // font-size: 14px;
  color: var(--text-red);

  &:empty {
    display: none;
  }
}

.success-feedback {
  display: block;
  font-size: 14px;
  color: var(--text-green);
  width: 100%;
  margin-top: 0.25rem;

  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1A1F36;
  margin-bottom: 10px;

  .required {
    color: var(--text-red);
  }
}

.label-secondary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;

  .required {
    color: var(--text-red);
  }

  &.dark {
    color: var(--text-white);
  }
}

.btn {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: .9;
    background-color: #000000;
    color: #FFFFFF;
  }

  &:disabled {}
}

.btn__icon {
  margin-right: 0.5rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.6875rem 1rem;
}

.tab_btn--container {
  margin-top: 15px;
}

.tab__btn {
  color: var(--text-gray);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 23px;
  border-radius: 100px;
  border: none;
  background-color: transparent;

  &.active {
    background: rgba(77, 27, 108, 0.1);
    color: var(--purple);
  }
}

.btn__purple {
  color: var(--text-white);
  background: var(--bg-purple);
  border-color: var(--border-purple);
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #4D1B6C, 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: var(--text-white);
    background: var(--bg-purple);
    border-color: var(--border-purple);
  }
}

.btn__orange {
  color: var(--text-white);
  background: var(--orange);
  border-color: var(--orange);
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: var(--text-white);
    background: var(--orange);
    border-color: var(--orange);
  }
}

.btn__green {
  color: var(--text-white);
  background: var(--bg-secondary-color);
  border-color: var(--border-sky);

  &:hover,
  &:focus,
  &:active {
    color: var(--text-white);
    background: var(--bg-secondary-color);
    border-color: var(--border-sky);
  }
}

.btn__purple--outline {
  color: var(--text-pink);
  background: var(--bg-white);
  border-color: var(--text-white);

  &:hover,
  &:focus,
  &:active {
    color: var(--text-pink);
    background: var(--bg-white);
    border-color: var(--text-white);
  }
}


.btn__default {
  color: #3C4257;
  background: var(--bg-white);
  border-color: var(--text-white);
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16),
    0px 1px 1px rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: #3C4257;
    background: var(--bg-white);
    border-color: var(--text-white);
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16),
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__link {
  color: var(--text-pink);
  text-decoration: none;
  padding: 0 0.5rem;

  &:hover,
  &:focus,
  &:focus {
    color: var(--text-pink);
  }
}

.btn__link--underline {
  color: var(--text-pink);
  text-decoration: underline;

  &:hover,
  &:focus,
  &:focus {
    color: var(--text-pink);
    text-decoration: underline;
  }
}

.btn__link--deafult {
  color: var(--text-black);
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: var(--text-black);
    text-decoration: none;
  }
}

.btn__link--danger {
  color: var(--text-red);
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: var(--text-red);
    text-decoration: none;
  }
}

.btn__delete--icon {
  color: var(--delete);
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: var(--delete);
    text-decoration: none;
  }
}

.btn__text {
  color: var(--purple);
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.card {
  border: 0;
  box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: var(--bold);
      font-size: 22px;
      line-height: 26px;
      color: var(--text-black);
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

// Bootsrap overwrite

// Base 
.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

// Impersonate
.impersonate {
  background: #F7D9FF;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #C800FF;
    text-align: center;

    .impersonate__link {
      color: #C800FF;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}

// Impersonate

// Main
.main {
  &[role="header"] {
    padding-top: 80px;
  }

  &[role="header-with-tabs"] {
    padding-top: 104px;

    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }

  &[role="head"] {
    padding-top: 66px;
  }

  &[role="tabs"] {
    padding-top: 104px;
  }

  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

// Main

// Base 


// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;

}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}


.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;

    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  &.border-bottom {
    border-bottom: 1px solid var(--light-gray);
  }

  .react-modal-title {
    font-weight: var(--bold);
    font-size: 16px;
    line-height: 22px;
    color: var(--text-black-shade-a);
    margin-bottom: 0;

    &.react-modal-title-style-2 {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: var(--text-black);
    margin-bottom: 0;

    &.react-modal-subtitle-style-2 {

      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #000000;
    }
  }

  .react-modal-close {
    position: absolute;
    top: 18px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
  background: #F7FAFC;

  &.react-modal-body-style-2 {
    background: #fff;
  }
}

.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: var(--bg-white);
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 20px;
  }
}

// React Modal Css

// Genral Css


// login page css open
.WhiteBackground {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  background-color: #fff;

  @media (max-width: 767px) {
    background: #fff;
  }

  .Flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100vh;
    max-width: 1900px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 992px) {
      align-items: flex-start;
      width: 100%;
      padding-top: 60px;
    }

    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
}

.PureWhite {
  background: #fff !important;
}

.LeftSection,
.RightSection {
  width: 50%;
}

.RightSectionInner {
  width: 540px;
  max-width: 100%;
  padding-left: 100px;
  box-sizing: border-box;

  @media (max-width: 1160px) {
    padding-left: 40px;
    padding-right: 60px;
  }

  @media (max-width: 992px) {
    padding: 0;
    width: 100%;
  }

  p {
    margin-top: 10px;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.BrandLogo {
  width: 186px;

  @media (max-width: 767px) {
    width: 130px;
  }
}

.LeftSection {
  padding-left: 100px;
  padding-top: 0px;

  @media (max-width: 1280px) {
    padding-left: 50px;
  }

  @media (max-width: 992px) {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }

  h3 {
    margin-top: 12px;
    margin-bottom: 100px;

    @media (max-width: 1366px) {
      margin-top: 4px;
      margin-bottom: 70px;
      font-size: 16px;
    }

    @media (max-width: 992px) {
      margin-bottom: 0px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .LoginImage {
    position: relative;
    padding-top: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      position: absolute;
      top: -40px;
      left: -40px;
    }
  }

  .LoginImageBox {
    width: 100%;
    max-width: 510px;
    margin-left: 100px;
    padding-bottom: 60px;
    position: relative;

    @media (max-width: 1440px) {
      max-width: 70%;
      margin-left: 70px;
    }

    @media (max-width: 1280px) {
      margin-left: 70px;
      max-width: 300px;
    }

    @media (max-width: 1160px) {
      margin-left: 50px;
      max-width: 370px;
    }

    @media (max-width: 992px) {
      display: none;
    }

    .YellowCircleRight {
      position: absolute;
      bottom: -24px;
      right: -80px;
      width: 240px;
      height: 190px;
      background: url('../images/img-yellow-left-circle.svg') no-repeat;
      background-size: contain;
      z-index: 1;
      display: none;

      @media (max-width: 1366px) {
        width: 200px;
        height: 160px;
      }
    }
  }
}

.RightSection {
  @media (max-width: 992px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.YellowCircleLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 170px;
  background: url('../images/img-yellow-right-circle.svg') no-repeat;
  background-size: contain;
  z-index: 1;
  display: none;

  @media (max-width: 1366px) {
    height: 120px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.FormBg {
  .form-label {
    font-size: 12px;
    line-height: 0%;
    color: #010101;
    opacity: 0.7;
    margin: 30px 0 14px 0;
    width: 100%;
  }

  .InputStyle {
    width: 100%;
    height: 55px;
    border: 1px solid #B3B3B3;
    border-radius: 6px;
    background-color: #fff;
    padding: 0 14px;

    &:focus {
      background-color: #fff;
    }
  }

  .PasswordStyle {
    width: 100%;
    height: 52px;
    border-radius: 6px;
    background-color: #fff;
    border: 0;
    padding-right: 50px;

    &:focus {
      background-color: #fff;
      border: 0;
      box-shadow: none;
    }
  }

  .flex-password {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    border: 1px solid #B3B3B3;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
  }

  .form-check-input:checked {
    background-color: #000 !important;
    border-color: #000 !important;
  }

  label {
    font-size: 14px;
    color: #838383;
  }

  .InputWrap {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.changePwWrapper {
  .flex-password {
    position: relative;
    display: flex;
    align-items: center;
  }
}

.passwordbtn {
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  width: 30px;
  padding: 0;
  text-align: center;

  &:focus {
    outline: none;
  }

  img {
    width: 24px;
  }
}

.InputStyle {
  padding-right: 50px;
}

.ActionImg {
  position: absolute;
  right: 10px;
  width: 24px;
}

.Terms {
  font-size: 14px;
  text-decoration-line: underline;
  color: #010101;
  font-weight: 600;

  &:focus,
  &:hover {
    color: #010101;
  }
}

.ForgotPassword {
  font-weight: 600;
  font-size: 12px;
  color: #010101;
  text-decoration: none;
  margin-bottom: 60px;
  width: auto;
  display: inline-block;

  &:focus,
  &:hover {
    color: #010101;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.btn.BtnDark {
  width: 100%;
  height: 55px;
  background-color: #000000;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  letter-spacing: 1px;

  &:focus,
  &:hover {
    background-color: #000000;
    color: #FFFFFF;
  }
}

.btn.BtnWhiteTransparent {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

// login page css close




// Dashboard css open
.DashboardSection {
  display: flex;
  flex-wrap: wrap;
  padding: 120px 60px 0 60px;
  height: calc(100vh - 124px);

  @media (max-width:1160px) {
    padding: 120px 20px 20px 20px;
    height: auto;
  }

  @media (max-width:767px) {
    padding: 90px 20px 20px 20px;
  }

  .LeftSection {
    width: 1018px;
    padding: 34px 0;

    @media (max-width:1600px) {
      width: 900px;
    }

    @media (max-width:1440px) {
      width: 800px;
    }

    @media (max-width:1365px) {
      width: 700px;
    }

    @media (max-width:1100px) {
      width: 100%;
    }

    @media (max-width:767px) {
      padding: 0;
      text-align: left;
      margin-bottom: 0;
    }

    .StatsList {
      list-style: none;
      margin: 34px -10px 60px -10px;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media (max-width:1160px) {
        margin: 14px 0 40px 0;
      }

      @media (max-width:767px) {
        margin: 14px -10px 0px -10px;
      }

      li {
        // width: 240px;
        width: calc(25% - 20px);
        // height: 232px;
        margin: 0 10px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 0 10px 0;
        background: #FFFFFF;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        position: relative;

        @media (max-width:1440px) {
          padding: 20px 0 10px 0;
          height: auto;
        }

        @media (max-width:1365px) {
          margin: 0 20px 20px 0;
          max-width: 100%;
          // width: calc(50% - 20px);
        }

        @media (max-width:767px) {
          margin: 0 10px 30px 10px;
          max-width: 100%;
          width: calc(50% - 20px);
          padding: 20px 0 0px 0;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -14px;
          right: 10px;
          left: 10px;
          width: calc(100% - 20px);
          height: 15px;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 0 0 16px 16px;
        }

        // &::before {
        //   content: "";
        //   background: url('../images/list-shwdow-bg.svg') no-repeat;
        //   position: absolute;
        //   bottom: -21px;
        //   right: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 22px;
        //   background-size: 100%;
        //   background-position-y: bottom;
        //   border-top: 1px solid #f5f5f5;
        //   @media (max-width:767px) {
        //     bottom: -16px;
        //     height: 16px;
        //   }
        // }

        &:nth-child(3) {
          // @media (max-width:767px) {
          //   width: 100%;
          //   max-width: 100%;
          //   margin-top: 20px;
          // }
        }

        h1 {
          font-size: 60px;
          line-height: 120%;
          color: #1E1E1E;
          width: 100%;
          margin-top: 0px;
          margin-bottom: 10px;
          font-family: 'Lato-ExtraBold';

          @media (max-width:1600px) {
            font-size: 60px;
          }

          @media (max-width:1380px) {
            font-size: 50px;
          }

          @media (max-width:767px) {
            font-size: 46px;
            -webkit-text-stroke: 2px;
            margin-bottom: 30px;
          }
        }

        h6 {
          // font-family: $Nuetrif;
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: #7D7878;
          border-top: 1px solid rgba(30, 30, 30, 0.2);
          padding: 14px 0 14px 0;
          width: 100%;

          @media (max-width:1440px) {
            padding: 14px 0 4px 0;
          }

          @media (max-width:767px) {
            padding: 10px 0 10px 0;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }

      .LotPay {
        background: #00B028;

        &::before {
          content: "";
          position: absolute;
          bottom: -14px;
          right: 10px;
          left: 10px;
          width: calc(100% - 20px);
          height: 15px;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 0 0 16px 16px;
          background: #00B028;
        }


        // &::before {
        //   content: "";
        //   background: url('../images/list-shwdow-bg-green.svg') no-repeat;
        //   position: absolute;
        //   bottom: -21px;
        //   right: 7px;
        //   left: 7px;
        //   width: calc(100% - 14px);
        //   height: 22px;
        //   background-size: 100%;
        //   background-position-y: bottom;
        //   @media (max-width:767px) {
        //     bottom: -16px;
        //     height: 16px;
        //   }
        // }

        h1 {
          // font-weight: 800;
          // font-size: 80px;
          // line-height: 104px;
          color: #fff;
          width: 100%;
          margin-top: 0px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;


          // @media (max-width:767px) {
          //   font-size: 64px;
          //   line-height: 80px;
          // }
        }

        h6 {
          color: #fff;
          border-top: 1px solid rgba(221, 221, 221, 0.5);

          @media (max-width:1440px) {
            padding: 14px 0 4px 0;
          }

          @media (max-width:767px) {
            padding: 10px 0 4px 0;
          }
        }

        .DollorSign {
          width: 44px;
          margin-right: 7px;

          @media (max-width:1440px) {
            width: 35px;
          }

          @media (max-width:767px) {
            width: 30px;
            margin-top: 0;
          }
        }
      }
    }
  }

  .RightSection {
    padding: 34px 0px 0 50px;
    width: calc(100% - 1018px);

    @media (max-width:1600px) {
      width: calc(100% - 900px);
    }

    @media (max-width:1440px) {
      width: calc(100% - 800px);
    }

    @media (max-width:1365px) {
      width: calc(100% - 700px);
    }

    @media (max-width:1100px) {
      width: 100%;
      padding: 0;
    }

    .LotSection {
      padding: 40px 0 60px 0;
      display: flex;
      flex-wrap: wrap;

      @media (max-width:767px) {
        padding: 14px 0 40px 0;
      }

      .LotListing {
        width: calc(100% - 280px);
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 30px 30px;
        box-sizing: border-box;
        margin-right: 20px;
        height: 195px;
        overflow-y: auto;

        @media (max-width:1160px) {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
        }

        li {
          background: #FEFF0D;
          border-radius: 20px;
          font-size: 14px;
          line-height: normal;
          font-weight: 500;
          text-align: center;
          color: #1E1E1E;
          padding: 7px 14px;
          margin-right: 7px;
          margin-bottom: 8px;
          border: 1px solid #eaeb00;
          display: inline-block;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            background: #1E1E1E;
            border: 1px solid #1E1E1E;
            color: #fff;
          }
        }
      }


    }

    .LotAvailable {
      height: calc(100vh - 300px);
      padding: 20px 34px 20px 14px;
      background: #fff;
      box-shadow: 0px 8px 30px rgb(0 0 0 / 10%);
      border-radius: 20px;
      margin-top: 30px;

      @media (max-width:991px) {
        height: auto;
        margin-bottom: 30px;
      }

      @media (max-width:767px) {
        margin-top: 14px;
        padding: 20px 10px 20px 10px;
      }

      .LotAvailableList {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        padding: 0px 0 30px 0;
        overflow-y: auto;

        @media (max-width:991px) {
          height: 300px;
          max-height: 300px;
          position: relative;
          z-index: 9;
        }

        li {

          a,p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding: 14px 10px;
            cursor: pointer;

            @media (max-width:767px) {
              padding: 20px 10px;
            }
          }

          .LotTitle {
            // display: flex;
            // font-family: $Nuetrif;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #444444;
            align-items: center;
            width: calc(100% - 120px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .LotLabel {
            border-radius: 5px;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 8px 20px;
            width: 100px;

            @media (max-width:767px) {
              padding: 7px 10px;
              width: auto;
            }
          }

          .LotLabelGreen {
            background: rgba(16, 188, 54, 0.2);
            color: #10BC36;
            transition: all .3s ease-in-out;
          }

          .LotLabelPink {
            background: rgba(240, 19, 19, 0.2);
            color: #F01313;
            transition: all .3s ease-in-out;
            white-space: nowrap;
          }

          .LotLabelOrange {
            background: rgba(240, 178, 19, 0.2);
            border-radius: 5px;
            color: #F0B213;
            transition: all .3s ease-in-out;
          }


          &:hover {
            .LotLabelGreen {
              background: rgba(16, 188, 54, 0.9);
              color: #fff;
            }

            .LotLabelOrange {
              background: rgba(240, 178, 19, 0.9);
              color: #fff;
            }

            .LotLabelPink {
              background: rgba(240, 19, 19, 0.9);
              color: #fff;
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgb(208, 208, 208);
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {

      background: #000000;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }
  }
}

// Dashboard css close

.HoneyBg {
  background: url('../images/honey-backgound.png') no-repeat !important;
  background-size: cover !important;
  background-position: center bottom;

  @media (max-width:767px) {
    background: none !important;
  }
}

// .HeightAuto {
//   height: auto !important;
//   padding-bottom: 30px !important;
// }

.ProfileSection {
  background: url('../images/bg.png') no-repeat;
  background-size: 100%;
  background-position-y: bottom;
  height: 100vh;

  // min-height: 100%;
  @media (max-width:767px) {
    background: none;
  }

  .ProfileBg {
    width: 100%;
    background: url('../images/profile-bg.jpg') no-repeat;
    background-size: cover;
    padding: 0 26px;
    min-height: 100px;
    cursor: pointer;

    @media (max-width:1280px) {
      min-height: 100px;
      max-height: 100px;
    }

    @media (max-width:767px) {
      padding: 0 20px;
      min-height: 180px;
      max-height: 180px;
    }

    .wrapperprofile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .box {
      display: block;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: 10px;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      position: relative;
      top: 80px;
      border: 5px solid #FFE144;

      @media (max-width:1280px) {
        width: 120px;
        height: 120px;
        top: 40px;
      }

      @media (max-width:767px) {
        top: 100px;
      }
    }


    .upload-options {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    }
    
      .wrapperprofile .box + .tooltiptext {
        visibility: hidden;
        position: absolute;
        width: 356px;
        max-width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 16px;
        color: #1E1E1E;
        z-index: 1;
        text-align: left;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 260px;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        top: 120px;
        @media (max-width:1280px) {
          top: 40px;
        }
        @media (max-width:899px) {
          top: 40px;
          width: 300px;
          margin-left: 220px;
        }
        @media (max-width:767px) {
          top: 0px;
          margin-left: 0;
        }
        img {
          width: 34px;
          margin-right: 10px;
        }
    }
    
    .wrapperprofile .box:hover + .tooltiptext {
      visibility: visible;
    }

    .upload-options {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      cursor: pointer;
      overflow: hidden;
      text-align: center;
      transition: background-color ease-in-out 150ms;
      padding-top: 11px;
      padding-left: 8px;

      & input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      & label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          z-index: 0;
          background: url('../images/ic-edit.svg') no-repeat;
          width: 40px;
          height: 40px;
          background-size: 24px;
          top: 10px;
          left: 10px;
        }

        & span {
          display: inline-block;
          width: 50%;
          height: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          vertical-align: middle;
          text-align: center;
        }
      }

    }

    .js--image-preview {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% !important;
      // background: url('../images/default-profile.png');

      &::after {
        content: "";
        position: relative;
        font-size: 4.5em;
        color: rgba(230, 230, 230, 1);
        top: calc(50% - 3rem);
        left: calc(50% - 2.25rem);
        z-index: 0;
      }

      &.js--no-default::after {
        display: none;
      }

      &:nth-child(2) {
        background-image: url('http://bastianandre.at/giphy.gif');
      }
    }

    .drop {
      display: block;
      position: absolute;
      border-radius: 100%;
      transform: scale(0);
    }

    .animate {
      animation: ripple 0.4s linear;
    }

    @keyframes ripple {
      100% {
        opacity: 0;
        transform: scale(2.5);
      }
    }



    .ProfileImgBox {
      .ProfileImg {
        margin: 0 auto;
        display: block;
        width: 150px;
        height: 150px;
        position: relative;
        top: 65px;

        >img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          border: 5px solid #FFE144;
        }

        .EditBtn {
          border: 0;
          width: 42px;
          height: 42px;
          background: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          position: absolute;
          right: 0;
          bottom: 0;

          input {
            opacity: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: -5px;
          }

          img {
            z-index: 9;
            position: relative;
            width: 26px;
            top: -32px;
          }
        }
      }
    }
  }

  .YellowBg {
    max-width: 1099px;
    width: 100%;
    background: #FCFF00;
    border-radius: 10px;
    padding: 34px 40px;
    margin: 0 auto;

    @media (max-width:1366px) {
      padding: 24px 30px;
    }

    @media (max-width:1280px) {
      max-width: 635px;
    }

    @media (max-width:767px) {
      padding: 24px 20px;
    }

    >.Header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      height: auto;
      padding: 0;
      margin-bottom: 42px;

      @media (max-width:1366px) {
        margin-bottom: 20px;
      }

      @media (max-width:1280px) {
        margin-bottom: 10px;
      }

      .HeaderTitle {
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #1E1E1E;
        width: 100%;

        @media (max-width:1366px) {
          font-size: 20px;
          line-height: 26px;
        }

        @media (max-width:767px) {
          font-size: 18px;
          line-height: 26px;
          // padding-left: 36px;
        }
      }

      .HeaderClose {
        background: none;
        border: none;
        margin-left: auto;
        width: 40px;

        img {
          width: 100%;
        }
      }
    }

    .Paragraph {
      width: 100%;
      max-width: 755px;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      color: #1E1E1E;
      margin: 0 auto;

      @media (max-width:1366px) {
        font-size: 20px;
        line-height: 26px;
      }

      @media (max-width:1280px) {
        font-size: 18px;
        line-height: 26px;
      }

      @media (max-width:767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .Header {
    @media (max-width: 1440px) {
      padding: 0 30px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .done-icon {
    @media (max-width: 767px) {
      margin-bottom: 20px;
      width: 80px;
    }
  }

  .LotListingWrapper {
    border: 1px solid #000;
    border-radius: 20px;
    padding: 30px 30px 30px 60px;
    margin: 60px auto 20px;
    width: 90%;
    max-width: 680px;
    background: #FFFFFF;

    .LotAvailableList {
      height: 350px;
      overflow-y: auto;

      @media (max-width:767px) {
        margin: 20px 0 30px 0;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 5px 0;
        padding: 14px 14px 14px 0;

        .LotTitle {
          font-weight: 500;
          font-size: 20px;
          color: #1E1E1E;
        }
      }

      .form-group {
        display: block;
        margin-bottom: 0;
        width: 100%;
      }

      .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .form-group label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
      }

      .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: #D9D9D9;
        border: 2px solid #D9D9D9;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }

      .form-group input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        right: 14px;
        width: 6px;
        height: 14px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      .form-group input:checked+label:before {
        content: '';
        -webkit-appearance: none;
        background-color: #2F9E3A;
        border: 2px solid #2F9E3A;
      }


    }
  }

  .BtnListingWrapper {
    max-width: 497px;
    width: 100%;
    margin: 40px auto 0;
    display: flex;
    flex-wrap: wrap;

    a {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 10px;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #1E1E1E;
      padding: 14px 10px;
      margin: 0 0 12px 0;
      transition: all .3s ease-in-out;

      &:hover {
        background: #1E1E1E;
        border: 1px solid #1E1E1E;
        color: #fff;
      }
    }
  }

  .ContactWrapper {
    width: 100%;
    max-width: 650px;
    background: #FFFFFF;
    // box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #C1BBBB;
    border-radius: 10px;
    margin: 60px auto 0;
    text-align: center;
    padding: 34px 20px;

    @media (max-width: 1280px) {
      max-width: 580px;
      margin: 30px auto 0;
      padding: 24px 10px;
    }

    @media (max-width: 767px) {
      margin: 20px auto 40px;
      padding: 24px 10px;
    }

    .FlexContact {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;

      @media (max-width: 767px) {
        margin-top: 0px;
      }

      .CustomMarg {
        @media (max-width: 767px) {
          margin: 20px 0 0 0;
          width: 100%;
        }
      }
    }

    .DarkBtn {
      margin: 0;
      width: 298px;
      max-width: 100%;

      @media (max-width: 767px) {
        width: 100%;
        line-height: normal;
      }
    }

    .InputStyle {
      box-sizing: border-box;
      width: 100%;
      height: 44px;
      border: 1px solid #C1BBBB;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: left;
      color: #1E1E1E;
      padding: 0 14px;
    }

    .TextareaStyle {
      box-sizing: border-box;
      width: 100%;
      height: 170px;
      border: 1px solid #C1BBBB;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #1E1E1E;
      padding: 10px 14px;
      resize: none;

      @media (max-width: 1280px) {
        height: 100px;
      }

      &:focus {
        outline: none;
      }
    }

    .CustomMarg {
      margin: 0 20px;
    }

    .MailIcon {
      width: 30px;
      margin-right: 10px;
      object-fit: contain;
      height: 24px;
    }

    p {
      max-width: 616px;
      width: 100%;
      font-size: 16px;
      text-align: center;
      color: #252525;
      margin: 0 auto;
      @media (max-width: 767px) {
              font-size: 14px;
              text-align: left;
              margin: 10px 0 0 0;
        }

      a {
        color: #3D7AF0;
        text-decoration: underline;
      }
    }

    .DarkBtn {
      display: inline-flex;
    }
  }

  .changePwWrapper {
    width: 100%;
    max-width: 530px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 50px auto 0;
    text-align: center;
    padding: 36px 16px;
    border: 1px solid #CACACA;

    @media (max-width: 1280px) {
      max-width: 454px;
      padding: 26px 16px;
      margin: 30px auto 0;
    }

    @media (max-width: 767px) {
      padding: 22px 0px;
      margin: 20px auto;
    }

    .btn-wrapper {
      padding-top: 20px;
    }

    label {
      text-align: left;
      font-weight: 600;
      font-size: 21px;
      line-height: 30px;
      color: #1E1E1E;
      margin-bottom: 20px;

      @media (max-width: 1366px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 6px;
      }

      @media (max-width: 1280px) {
        margin-bottom: 0;
      }
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 44px;
      border: 0;
      border-bottom: 1px solid #C1BBBB;
      border-radius: 0;
      font-size: 18px;
      line-height: 26px;
      text-align: left;
      color: #000;

      @media (max-width: 1366px) {
        font-size: 16px;
        line-height: 22px;
      }

      &::placeholder {
        color: #B7B7B7;
      }

    }

    .BtnWrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .DarkBtn {
      width: 298px;
      max-width: 100%;
      min-height: 54px;
      margin: 0;
    }
  }
}

.profile-bg {
  background: #fff url('../images/home-bg.png') no-repeat;
  background-size: 100%;
  background-position-y: bottom;
  padding-top: 120px;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 40px;

  @media (max-width: 1366px) {
    padding-top: 80px;
  }

  @media (max-width: 767px) {
    background: none;
  }

  .ProfileForm {
    .DarkBtn {
      max-width: 200px;

      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.ProfileSectionYellow {
  display: flex;
  justify-content: center;
  padding-top: 180px;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 40px;

  @media (max-width: 1366px) {
    padding-top: 120px;
  }

  @media (max-width: 1280px) {
    padding-top: 100px;
  }

  @media (max-width: 1200px) {
    padding: 100px 20px 0 20px;
  }


  .WidthFull {
    width: 100%;
  }
}

.ProfileForm {
  max-width: 1080px;
  width: 100%;
  margin: 110px auto 0;

  @media (max-width: 1280px) {
    max-width: 800px;
  }

  .form-field {
    padding: 0 30px;

    @media (max-width:767px) {
      padding: 0 5px;
      margin-bottom: 20px !important;
    }
  }

  .form-label {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #1E1E1E;
    display: block;
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 1280px) {
      font-size: 18px;
      margin-bottom: 0px;
    }
  }

  .form-control {
    width: 100%;
    height: 27px;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #010101;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 0;

    &::placeholder {
      color: #cacaca;
    }

    @media (max-width: 1280px) {
      font-size: 16px;
      margin-bottom: 0px;
    }

    &:disabled {
      background-color: #ffffff !important;
    }
  }

  .form-select {
    width: 100%;
    font-size: 18px;
    line-height: 29px;
    color: #010101;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin-top: 26px;
    padding-left: 0;
  }

  .BankDetailsFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px 0 32px 0;

    @media (max-width:767px) {
      justify-content: space-between;
    }
  }

  .BankDetails {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #1E1E1E;
    margin: 0 25px 0 0;

    @media (max-width:1280px) {
      font-size: 20px;
    }

    @media (max-width:767px) {
      font-size: 18px;
      margin: 0;
      line-height: normal;
    }
  }

  .linkAccount {
    // font-weight: 600;
    // font-size: 16px;
    // line-height: 29px;
    // color: #1E1E1E;
    // border: 1px solid #000;
    // border-radius: 5px;
    // text-align: center;
    // padding: 9px 24px;
    width: 270px;
    border: 0;
    padding: 0;
    background: none;

    &:focus {
      outline: none;
    }

    @media (max-width:767px) {
      width: 220px;
    }
  }

  .linkAccountStripe {
    width: 250px;
    border: 0;
    padding: 0;
    background: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #1E1E1E;
    border: 1px solid #000;
    border-radius: 5px;
    text-align: center;
    padding: 12px 24px;

    &:focus {
      outline: none;
    }

    @media (max-width:767px) {
      width: 220px;
    }
  }

  .DarkBtn {
    max-width: 400px;
    width: 100%;
  }
}

.CstmModal {
  .modal-dialog {
    height: 94vh;
    display: flex;
    align-items: center;

    .modal-header {
      background-color: #FCFF00;

      h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #1E1E1E;
        text-align: center;
        width: 100%;
      }

      .btn-close {
        opacity: 1;
      }
    }

    .RequireMsg {
      font-size: 18px;
      padding: 20px 0 0 20px;
    }

    .FooterFlexInline {
      display: flex;
      justify-content: flex-end;
      padding: 0px 30px 20px 30px;
      border: 0;

      .BlackBtn {
        width: 120px;
        height: 50px;
        background: #000000;
        border: 1px solid #000000;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        color: #FFFFFF;
      }

      .WhiteBtn {
        width: 120px;
        height: 50px;
        background: #fff;
        border: 1px solid #000000;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        color: #000000;
      }
    }
  }
}

.CstModalLg {
  width: 92%;
  max-width: 866px;
  max-height: 96vh;
}

.CstModalSm {
  width: 92%;
  max-width: 460px;
  max-height: 96vh;
  overflow-y: scroll;

  footer {
    padding: 24px 40px 30px 40px !important;

    .FooterFlex {
      display: block !important;
    }

    .FooterFlex.FooterFlexInline {
      display: flex !important;
      justify-content: center; 
      flex-wrap: wrap;

      .BlackBtn,
      .WhiteBtn {
        width: 47% !important;

        @media (max-width: 767px) {
          width: 100% !important;
        }
      }

      .BtnReady,
      .BtnDamaged {
        @media (max-width: 1280px) {
          min-height: 54px;
          min-width: 46%;
        }
      }
    }

    .BlackBtn,
    .WhiteBtn {
      width: 100% !important;
    }
  }
}

.file-upload {
  position: relative;
  overflow: hidden;
  border: 1px dashed rgb(179, 179, 179);
  white-space: nowrap;
  padding: 13px 35px;
  width: 100%;
  height: 70px;

  &:hover {
    border: 1px dashed rgb(179, 179, 179);
  }

  img {
    width: 25px;
    object-fit: contain;
    margin-right: 10px;
  }

  input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.CstModal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50px;
  transform: translate(-50%, -50%);
  padding: 0;
  border-radius: 14px;
  border: 0;
  position: absolute;
  background: #fff;

  header {
    width: 100%;
    min-height: 80px;
    background: #FCFF00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px 14px 0 0;
    padding: 14px 24px;
    // flex-wrap: wrap;

    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1E1E1E;
      text-align: center;
      width: 100%;
    }

    .CloseModalBtn {
      background: none;
      border: 0;
      padding: 0;
      width: 30px;
    }

    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1E1E1E;

      .LocationIcon {
        height: 13px;
        width: auto;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #1E1E1E;
      margin: 0;
      padding: 0 7px;
    }

    .MarTop {
      @media (max-width:767px) {
        margin-top: 20px;
      }
    }

    .ClockIcon {
      width: 24px;
    }

    .SendBtn {
      background: none;
      border: 0;

      img {
        height: 30px;
      }
    }
  }

  .ModayBody {
    padding: 30px 40px 0 40px;

    @media (max-width:767px) {
      padding: 30px 26px 0 26px;
    }

    .ImgListing {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 49%;
        position: relative;
        padding-top: 26%;
        margin-bottom: 14px;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: cover;
          border-radius: 7px;
        }
      }
    }

    .ParaBg {
      max-width: 438px;
      width: 100%;
      margin: 0 auto;

      h4 {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: #1E1E1E;
        margin: 0 0 20px 0;
      }

      ul {
        width: 300px;
        list-style: disc;
        margin: 0 auto;

        li {
          text-align: left;
          padding: 0 0 10px 0;
        }
      }
    }

    .InputStyle {
      box-sizing: border-box;
      width: 100%;
      height: 68px;
      border: 1px solid #C1BBBB;
      border-radius: 10px;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #1E1E1E;
    }

    .InputLabels {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1E1E1E;
      margin-bottom: 10px;
    }

    .InputStyles {
      box-sizing: border-box;
      width: 100%;
      height: 58px;
      border: 1px solid #C1BBBB;
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      text-align: left;
      color: #1E1E1E;
      padding: 0 14px;
    }

    .LicenseSearch {
      background: url('../images/ic-search-input.svg') no-repeat;
      background-position: calc(100% - 15px) center;
      background-size: 24px;
    }

    .Valid {
      color: #1E9014;
      margin-top: 10px;
    }

    .InValid {
      color: #f10000;
      margin-top: 10px;
    }
  }

  .SearchPlateFlex {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 58px;
    border: 1px solid #C1BBBB;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    text-align: left;
    color: #1E1E1E;
    padding: 0 14px 0 0;

    .InputStyles {
      border: 0;
      height: 56px;

    }
.SearchPlateBlock {
  background: #000;
  padding: 8px;
  border-radius:  6px;;
}
    .SearchPlate {
      background: none;
      border: 0;
      padding: 0;
      text-align: right;

      img {
        width: 24px;

      }
    }
  }

  footer {
    padding: 24px 24px 30px 24px;

    @media (max-width:767px) {
      padding: 24px 26px 14px 26px !important;
    }

    .FooterFlex {
      display: flex;
      justify-content: center;

      @media (max-width:767px) {
        flex-wrap: wrap !important;
      }
    }

    .BlackBtn {
      width: 170px;
      height: 55px;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      color: #FFFFFF;
    }

    .WhiteBtn {
      margin: 0 2px;
      width: 170px;
      height: 55px;
      background: #fff;
      border: 1px solid #000000;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      color: #000000;
      transition: all .3s ease-in-out;
      &:hover {
        background: #000000;
        border: 1px solid #000000;
        color: #fff;
      }
      &:disabled {
        border: 1px solid #999999;
        background-color: #FFFFFF;
        color: #666666;
        opacity: 0.6;
      }
    }
  }

  .RedText {
    font-size: 16px;
    line-height: 22px;
    color: #BE1818;
    margin-top: 20px;
  }

  .GreenText {
    font-size: 16px;
    line-height: 22px;
    color: #1E9114;
    margin-top: 5px;
  }
}


.ReactModal__Overlay {
  // background: url("../images/modalimg.png") no-repeat !important;
  // background-size: cover !important;
  margin-top: 124px;
  z-index: 9;

  @media (max-width: 1366px) {
    margin-top: 80px !important;
  }

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}

.RightSign {
  width: 60px;
}

.SuccessfullMsg {
  font-size: 20px;
  color: #000;
}

.HideGap {
  .ReactModal__Overlay {
    margin-top: 0;
  }
}

::-webkit-input-placeholder {
  color: #CACACA;
}

:-ms-input-placeholder {
  color: #CACACA;
}

::placeholder {
  color: #CACACA;
}


.NextPreBtn {
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-top: 1px solid #f1f1f1;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-top: 1px solid #f1f1f1;
    margin-top: 0;
  }

  .DarkBtn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.DarkBtn {
  background: #000;
  border: 1px solid #000;
  color: #fff;
  border-radius: 6px;
  padding: 14px 20px;

  &:hover,
  &:focus,
  &:active {
    background: #000 !important;
    border: 1px solid #000 !important;
    color: #fff !important;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    width: 47%;
  }
}

.LightBtn {
  background: #fff;
  border: 1px solid #D9D9D9;
  color: #000;

  &:hover,
  &:focus,
  &:active {
    background: #fff;
    border: 1px solid #D9D9D9;
    color: #000;
  }

  @media (max-width: 767px) {
    border: 1px solid #D9D9D9;
    width: 47%;
  }
}

// .InputStyleLowercase{
//   text-transform: lowercase;
// }

.NotificationButtonWrapper {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999999 !important;
  @media (max-width: 767px) {
    left: 10px;
  }

  .NotificationSuccessful {
    width: 400px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #10BC36;
      animation: border_anim 2s linear forwards;
      border-radius: 0 0 5px 5px;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .NotificationWarning {
    width: 400px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #FFDE59;
      animation: border_anim 3s linear forwards;
      border-radius: 0 0 5px 5px;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .NotificationError {
    width: 400px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #FF3131;
      animation: border_anim 3s linear forwards;
      border-radius: 0 0 5px 5px;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .NotificationInformational {
    width: 400px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #199BF8;
      animation: border_anim 3s linear forwards;
      border-radius: 0 0 5px 5px;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

}

@keyframes border_anim {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.Error {
  color: rgb(255, 0, 0);
  text-align: start;
}

.dataText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-block {
  display: flex;
  justify-content: center;
  margin: 23px 0;

  @media screen and (max-width:1280px) {
    margin: 18px 0;
  }

  ul {
    li {
      min-width: 25px !important;
      height: 25px !important;

      a {
        height: 100%;
        line-height: 23px;
      }

      button {
        &:last-child {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        &:first-child {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-item {
    border: 1px solid #000;
    min-width: 30px;
  }
}
.TermsText {
  margin: 0;
  a {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
  }
}
.AddressGoogle {
  .css-13cymwt-control {
    border: 0 !important;
    border-bottom: 1px solid #ccc !important;
    min-height: 44px !important;
  }
  .css-1u9des2-indicatorSeparator {
    background-color: transparent;
  }
  .css-t3ipsp-control:hover, 
  .css-t3ipsp-control {
    border: 0 !important;
    border-bottom: 1px solid #ccc !important;
    outline: none;
    min-height: 44px !important;
    box-shadow: none;
  }
}
.AccessDenied {
  padding: 30px 50px;
  h4 {
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
  }
  p {
    font-weight: 400;
    font-size: 22px;
    color: #010101;
  }
}
.AddressLocation {
  white-space:pre-line;
  max-width: 320px;
}