// node_modules css
@import 'antd/dist/antd.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
// node_modules css
@import '../theme/global.scss';
@import '../theme/variable.scss';
@import url("../views/LotRules/style.scss");

body {
  // font-family: $SFProText, sans-serif;
  font-family: var(--font-Lato);
  font-size: var(--fontbase);
  color: var(--text-black);
}