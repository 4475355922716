@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Light.eot');
    src: url('NeutrifPro-Light.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Light.woff2') format('woff2'),
        url('NeutrifPro-Light.woff') format('woff'),
        url('NeutrifPro-Light.ttf') format('truetype'),
        url('NeutrifPro-Light.svg#NeutrifPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Bold.eot');
    src: url('NeutrifPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Bold.woff2') format('woff2'),
        url('NeutrifPro-Bold.woff') format('woff'),
        url('NeutrifPro-Bold.ttf') format('truetype'),
        url('NeutrifPro-Bold.svg#NeutrifPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-BoldItalic.eot');
    src: url('NeutrifPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-BoldItalic.woff2') format('woff2'),
        url('NeutrifPro-BoldItalic.woff') format('woff'),
        url('NeutrifPro-BoldItalic.ttf') format('truetype'),
        url('NeutrifPro-BoldItalic.svg#NeutrifPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Light_1.eot');
    src: url('NeutrifPro-Light_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Light_1.woff2') format('woff2'),
        url('NeutrifPro-Light_1.woff') format('woff'),
        url('NeutrifPro-Light_1.ttf') format('truetype'),
        url('NeutrifPro-Light_1.svg#NeutrifPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Bold_1.eot');
    src: url('NeutrifPro-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Bold_1.woff2') format('woff2'),
        url('NeutrifPro-Bold_1.woff') format('woff'),
        url('NeutrifPro-Bold_1.ttf') format('truetype'),
        url('NeutrifPro-Bold_1.svg#NeutrifPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Bold_2.eot');
    src: url('NeutrifPro-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Bold_2.woff2') format('woff2'),
        url('NeutrifPro-Bold_2.woff') format('woff'),
        url('NeutrifPro-Bold_2.ttf') format('truetype'),
        url('NeutrifPro-Bold_2.svg#NeutrifPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-BoldItalic_1.eot');
    src: url('NeutrifPro-BoldItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-BoldItalic_1.woff2') format('woff2'),
        url('NeutrifPro-BoldItalic_1.woff') format('woff'),
        url('NeutrifPro-BoldItalic_1.ttf') format('truetype'),
        url('NeutrifPro-BoldItalic_1.svg#NeutrifPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Light_2.eot');
    src: url('NeutrifPro-Light_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Light_2.woff2') format('woff2'),
        url('NeutrifPro-Light_2.woff') format('woff'),
        url('NeutrifPro-Light_2.ttf') format('truetype'),
        url('NeutrifPro-Light_2.svg#NeutrifPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-BoldItalic_2.eot');
    src: url('NeutrifPro-BoldItalic_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-BoldItalic_2.woff2') format('woff2'),
        url('NeutrifPro-BoldItalic_2.woff') format('woff'),
        url('NeutrifPro-BoldItalic_2.ttf') format('truetype'),
        url('NeutrifPro-BoldItalic_2.svg#NeutrifPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-LightItalic.eot');
    src: url('NeutrifPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-LightItalic.woff2') format('woff2'),
        url('NeutrifPro-LightItalic.woff') format('woff'),
        url('NeutrifPro-LightItalic.ttf') format('truetype'),
        url('NeutrifPro-LightItalic.svg#NeutrifPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-LightItalic_1.eot');
    src: url('NeutrifPro-LightItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-LightItalic_1.woff2') format('woff2'),
        url('NeutrifPro-LightItalic_1.woff') format('woff'),
        url('NeutrifPro-LightItalic_1.ttf') format('truetype'),
        url('NeutrifPro-LightItalic_1.svg#NeutrifPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-LightItalic_2.eot');
    src: url('NeutrifPro-LightItalic_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-LightItalic_2.woff2') format('woff2'),
        url('NeutrifPro-LightItalic_2.woff') format('woff'),
        url('NeutrifPro-LightItalic_2.ttf') format('truetype'),
        url('NeutrifPro-LightItalic_2.svg#NeutrifPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Medium.eot');
    src: url('NeutrifPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Medium.woff2') format('woff2'),
        url('NeutrifPro-Medium.woff') format('woff'),
        url('NeutrifPro-Medium.ttf') format('truetype'),
        url('NeutrifPro-Medium.svg#NeutrifPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Medium_1.eot');
    src: url('NeutrifPro-Medium_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Medium_1.woff2') format('woff2'),
        url('NeutrifPro-Medium_1.woff') format('woff'),
        url('NeutrifPro-Medium_1.ttf') format('truetype'),
        url('NeutrifPro-Medium_1.svg#NeutrifPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Medium_2.eot');
    src: url('NeutrifPro-Medium_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Medium_2.woff2') format('woff2'),
        url('NeutrifPro-Medium_2.woff') format('woff'),
        url('NeutrifPro-Medium_2.ttf') format('truetype'),
        url('NeutrifPro-Medium_2.svg#NeutrifPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-MediumItalic.eot');
    src: url('NeutrifPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-MediumItalic.woff2') format('woff2'),
        url('NeutrifPro-MediumItalic.woff') format('woff'),
        url('NeutrifPro-MediumItalic.ttf') format('truetype'),
        url('NeutrifPro-MediumItalic.svg#NeutrifPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-MediumItalic_1.eot');
    src: url('NeutrifPro-MediumItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-MediumItalic_1.woff2') format('woff2'),
        url('NeutrifPro-MediumItalic_1.woff') format('woff'),
        url('NeutrifPro-MediumItalic_1.ttf') format('truetype'),
        url('NeutrifPro-MediumItalic_1.svg#NeutrifPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-MediumItalic_2.eot');
    src: url('NeutrifPro-MediumItalic_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-MediumItalic_2.woff2') format('woff2'),
        url('NeutrifPro-MediumItalic_2.woff') format('woff'),
        url('NeutrifPro-MediumItalic_2.ttf') format('truetype'),
        url('NeutrifPro-MediumItalic_2.svg#NeutrifPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Regular.eot');
    src: url('NeutrifPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Regular.woff2') format('woff2'),
        url('NeutrifPro-Regular.woff') format('woff'),
        url('NeutrifPro-Regular.ttf') format('truetype'),
        url('NeutrifPro-Regular.svg#NeutrifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Regular_1.eot');
    src: url('NeutrifPro-Regular_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Regular_1.woff2') format('woff2'),
        url('NeutrifPro-Regular_1.woff') format('woff'),
        url('NeutrifPro-Regular_1.ttf') format('truetype'),
        url('NeutrifPro-Regular_1.svg#NeutrifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-Regular_2.eot');
    src: url('NeutrifPro-Regular_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-Regular_2.woff2') format('woff2'),
        url('NeutrifPro-Regular_2.woff') format('woff'),
        url('NeutrifPro-Regular_2.ttf') format('truetype'),
        url('NeutrifPro-Regular_2.svg#NeutrifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-RegularItalic.eot');
    src: url('NeutrifPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-RegularItalic.woff2') format('woff2'),
        url('NeutrifPro-RegularItalic.woff') format('woff'),
        url('NeutrifPro-RegularItalic.ttf') format('truetype'),
        url('NeutrifPro-RegularItalic.svg#NeutrifPro-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-RegularItalic_1.eot');
    src: url('NeutrifPro-RegularItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-RegularItalic_1.woff2') format('woff2'),
        url('NeutrifPro-RegularItalic_1.woff') format('woff'),
        url('NeutrifPro-RegularItalic_1.ttf') format('truetype'),
        url('NeutrifPro-RegularItalic_1.svg#NeutrifPro-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-RegularItalic_2.eot');
    src: url('NeutrifPro-RegularItalic_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-RegularItalic_2.woff2') format('woff2'),
        url('NeutrifPro-RegularItalic_2.woff') format('woff'),
        url('NeutrifPro-RegularItalic_2.ttf') format('truetype'),
        url('NeutrifPro-RegularItalic_2.svg#NeutrifPro-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBold.eot');
    src: url('NeutrifPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBold.woff2') format('woff2'),
        url('NeutrifPro-SemiBold.woff') format('woff'),
        url('NeutrifPro-SemiBold.ttf') format('truetype'),
        url('NeutrifPro-SemiBold.svg#NeutrifPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBoldItalic.eot');
    src: url('NeutrifPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBoldItalic.woff2') format('woff2'),
        url('NeutrifPro-SemiBoldItalic.woff') format('woff'),
        url('NeutrifPro-SemiBoldItalic.ttf') format('truetype'),
        url('NeutrifPro-SemiBoldItalic.svg#NeutrifPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBold_1.eot');
    src: url('NeutrifPro-SemiBold_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBold_1.woff2') format('woff2'),
        url('NeutrifPro-SemiBold_1.woff') format('woff'),
        url('NeutrifPro-SemiBold_1.ttf') format('truetype'),
        url('NeutrifPro-SemiBold_1.svg#NeutrifPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBold_2.eot');
    src: url('NeutrifPro-SemiBold_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBold_2.woff2') format('woff2'),
        url('NeutrifPro-SemiBold_2.woff') format('woff'),
        url('NeutrifPro-SemiBold_2.ttf') format('truetype'),
        url('NeutrifPro-SemiBold_2.svg#NeutrifPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBoldItalic_1.eot');
    src: url('NeutrifPro-SemiBoldItalic_1.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBoldItalic_1.woff2') format('woff2'),
        url('NeutrifPro-SemiBoldItalic_1.woff') format('woff'),
        url('NeutrifPro-SemiBoldItalic_1.ttf') format('truetype'),
        url('NeutrifPro-SemiBoldItalic_1.svg#NeutrifPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('NeutrifPro-SemiBoldItalic_2.eot');
    src: url('NeutrifPro-SemiBoldItalic_2.eot?#iefix') format('embedded-opentype'),
        url('NeutrifPro-SemiBoldItalic_2.woff2') format('woff2'),
        url('NeutrifPro-SemiBoldItalic_2.woff') format('woff'),
        url('NeutrifPro-SemiBoldItalic_2.ttf') format('truetype'),
        url('NeutrifPro-SemiBoldItalic_2.svg#NeutrifPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

